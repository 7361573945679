
    import Quiz from "@/components/quiz.vue";
    import { Component, Vue } from "vue-property-decorator";

    @Component({
        components: {
            Quiz
        },
    })

    // *****************************************************************************************************************
    export default class DevCPlusPlus extends Vue {
        // Data --------------------------------------------------------------------------------------------------------
        private data() {
            return {
                cPlusPlusIconPathAndName:
                    require("@/assets/images/c_plus_plus.png"),

                leadProgrammerIconPathAndName:
                    require("@/assets/images/icon_lead_programmer.png"),

                w3SchoolsQuiz:
                    require("@/assets/quizzes/c-plus-plus-w3-schools-quiz.json"),
            };
        }
    }
